import React, { useEffect, useState } from "react"
import "../../styles/global.css"

const ScrollSlider = () => {
    const [scrollWidth, setScrollWidth] = useState(0)

    const handleScroll = () => {
        const scrollPosition = window.scrollY
        const maxScroll = document.documentElement.scrollHeight - window.innerHeight
        const percentage = (scrollPosition / maxScroll) * 100
        setScrollWidth(percentage)
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    return <div className="scroll-slider" style={{ width: `${scrollWidth}%` }}></div>
}

export default ScrollSlider
