import React from 'react'
import "../../styles/footer.css"
// import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import logowhite from '../../images/logo-svg-w.svg'

const Footer = () => {

  const links = [
    {
      name: 'Facebook',
      icon: "pi pi-facebook",
      path: "https://m.facebook.com/instacloudsolu"
    },
    {
      name: 'Twitter',
      icon: "pi pi-twitter",
      path: "https://twitter.com/InstaCloudSol"
    },
    {
      name: 'Linkedin',
      icon: "pi pi-linkedin",
      path: "https://in.linkedin.com/company/instacloudsolutions"
    },
    {
      name: 'Instagram',
      icon: "pi pi-instagram",
      path: "https://www.instagram.com/instacloudsolutions?igsh=MXhjdDhrYzYxemkxcA=="
    },
    {
      name: 'Youtube',
      icon: "pi pi-youtube",
      path: "https://www.youtube.com/channel/UCJP-v13t8IWaJDnuDg7Y99w"
    }
  ]

  return (
    // <footer className="footer-background  position-relative">
    <>
      <div className='footer-first'>
        <div className='image custom-z-index position-relative'>
          <Link to="/">
            {/* <StaticImage placeholder="blurred" src="../../images/logo.png" height={70} width={173} alt="logo" /> */}
            <img src={logowhite} height={55} width={140} alt="logo" />
          </Link>
        </div>
        <div className='flex-basis position-relative custom-z-index'>
          <h2 className='text-light fw-semibold'>Our Office</h2>
          <div>
            <div className='quick-info'>
              <p>
                <span className='d-block fw-semibold text-light'>Address :</span>
                {/* 701- Money Plant Highstreet, Jagatpur Road, Sarkhej - Gandhinagar Hwy, near BSNL Office, Gota, Ahmedabad, Gujarat 382470 */}
                701- Money Plant Highstreet, Jagatpur Road
                <br />
                Sarkhej - Gandhinagar Hwy, near BSNL Office
                <br />
                Gota, Ahmedabad, Gujarat 382470
              </p>
            </div>
            <div className='quick-info'>
              <p>
                <span className='d-block fw-semibold text-light'>Phone :</span>
                <a href='tel:+91 91732 01238'><span className='fs-6'>+91 91732 01238</span></a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='footer-second'>
        <div className='flex-basis custom-z-index position-relative'>
          <h1>Let's talk!</h1>
          <p>
            <a href='mailto:sales@instacloudsolutions.com'>sales@instacloudsolutions.com</a>
          </p>
        </div>
        <div className='flex-basis'>
          <div className='footer-industries-technologies'>
            <div className='quick-info custom-z-index position-relative'>
              <div className='footer-menu'>
                <ul className='footer-menu-items'>
                  <li className='footer-menu-item'>
                    <span className='text-light fw-semibold'>Our Services :</span>
                  </li>
                  <li className='footer-menu-item'><Link to='/services/strategic-consulting'>Strategic Consulting</Link></li>
                  <li className='footer-menu-item'><Link to='/services/operational-excellence'>Operational Excellence</Link></li>
                  <li className='footer-menu-item'><Link to='/services/migration-modernization'>Migration & Modernization</Link></li>
                  <li className='footer-menu-item'><Link to='/services/automation-integration'>AI & Automation Integration</Link></li>
                  {/* <li className='footer-menu-item'><Link to='/'>Non Profit</Link></li> */}
                </ul>
              </div>
            </div>
            <div className='quick-info custom-z-index position-relative'>
              <div className='footer-menu'>
                <ul className='footer-menu-items'>
                  <li className='footer-menu-item'>
                    <span className='text-light fw-semibold'>Technologies : </span>
                  </li>
                  <li className='footer-menu-item'><Link to='/technologies/microsoft-ecosystem'>Microsoft Ecosystem</Link></li>
                  <li className='footer-menu-item'><Link to='/technologies/low-code-innovation'>Low Code Innovation</Link></li>
                  <li className='footer-menu-item'><Link to='/technologies/azure-cloud-services'>Azure Cloud Services</Link></li>
                  <li className='footer-menu-item'><Link to='/technologies/data-intelligence'>Data Intelligence & AI</Link></li>
                  <li className='footer-menu-item'><Link to='/technologies/modern-development'>Modern Development Frameworks</Link></li>
                  {/* <li className='footer-menu-item'><Link to='/technologies/advanced-tools'>Advanced UI/UX Tools</Link></li> */}
                  {/* <li className='footer-menu-item'><Link to='/'>SharePoint</Link></li> */}
                  {/* <li className='footer-menu-item'><Link to='/'>Web Development</Link></li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='footer-third'>
        <div className='custom-z-index position-relative'>
          <p>
            © 2024 InstaCloud Solutions. All Rights Reserved
            <div className='mt-2'>
              <Link to='/privacy-policies'>Privacy Policies</Link>
            </div>
          </p>
        </div>
        <div className='footer-links custom-z-index position-relative'>
          {links.map((link, index) => (
            <a key={index} className={link.icon} href={link.path} aria-label={link.name}></a>
          ))}
        </div>
      </div>
      {/* <div className={`text-center p-3`}>
        © 2024 Copyright:
        <span className="fw-semibold"> ICS | Senif Bhoraniya</span>
      </div> */}
      {/* </footer> */}
    </>
  )
}

export default Footer
